import SanityBlockContent from '@sanity/block-content-to-react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'
/** @jsx jsx */
import { jsx, Container, Styled } from "theme-ui"

import Layout from '../components/layout'

const Project = ({ data }) => {
  const { project, siteSettings } = data

  if (!project) {
    return null
  }

  const header = siteSettings?.header

  const seo = project?.seo || siteSettings?.seo

  const ogImages = seo?.ogImages?.map(img => {
    return {
      url: img.image?.asset?.fluid?.src,
      alt: img.altText,
    }
  })
  return (
    <Layout siteSettings={siteSettings} header={header}>
      <GatsbySeo
        title={seo?.metaTitle || project.title}
        description={seo?.metaDescription}
        openGraph={ogImages}
      />
      <div
        sx={{
          pt: 6,
          display: `flex`,
          alignItems: `center`,
          flexDirection: `column`,
        }}
      >
        <div
          sx={{
            mb: 5,
            maxWidth: `600px`,
            mx: `auto`,
            textAlign: `center`,
            px: 3,
          }}
        >
          <h1>{project.title}</h1>
          <Styled.p>{project.shortDescription}</Styled.p>
        </div>
        <GatsbyImage image={project.mainImage?.asset?.gatsbyImageData} />
        <section
          sx={{
            px: 3,
            py: 5,
          }}
        >
          <Container sx={{ maxWidth: `900px` }}>
            <SanityBlockContent blocks={project._rawBody} />
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query ProjectQuery($projectId: String!) {
    siteSettings: sanitySiteSettings {
      siteName
      siteUrl
      seo {
        metaTitle
        metaDescription
        ogImages {
          altText
          image {
            asset {
              fluid(maxWidth: 1400, maxHeight: 800) {
                src
              }
            }
          }
        }
      }
      header {
        items {
          _key
          title
          internalLink {
            path {
              current
            }
          }
          externalLink
          relativePath
        }
      }
    }
    project: sanityProject(id: { eq: $projectId }) {
      title
      shortDescription
      mainImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 900, placeholder: NONE)
        }
      }
      gallery {
        _key
      }
      _rawBody
    }
  }
`

